import React from 'react';
import Helmet from 'react-helmet'

import { graphql } from 'gatsby'
import { Container, Heading, Text } from 'rebass'

import { Comments, Header, NavBar } from '../components'
import Layout from '../layouts/default'

const BlogPost = ({data}) => {
  const mdr = data.markdownRemark
  const site = data.site.siteMetadata
  const post = { slug: mdr.frontmatter.slug, url: `${site.siteUrl}${mdr.fields.path}`, path: mdr.fields.path, title: mdr.frontmatter.title, date: mdr.frontmatter.date, author: mdr.frontmatter.author, html: mdr.html}
  return (
  <Layout>
    <Helmet title={`${post.title}`} />
    <NavBar />
    <Header height='40vh' image='/assets/img/banners/macbook-desk-1200x900.jpg'>
      <Heading is='h1' fontSize={[4, 5, 6]} fontWeight='300' my={3}>{post.title}</Heading>
    </Header>
    <Container m={2}>
      <Text my={2} fontSize={[0, 1]} fontWeight='300'>{post.date}</Text>
      <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: post.html }}
      />
      <hr />
      <Comments data={post} />
    </Container>
  </Layout>
)}

export default BlogPost

export const pageQuery = graphql`
  query PageQuery($path: String!) {
    markdownRemark(fields: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        author
      }
      fields {
        path
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`
